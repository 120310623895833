<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Setting">
          <HeroSection v-if="moduleRole('hero')" :modulePage="modulePage" />
          <Overview v-if="moduleRole('overview')" @doChangeShowContent="doChangeShowContent(...arguments)" />
          <VisiMisi v-if="moduleRole('visi_misi')" @doChangeShowContent="doChangeShowContent(...arguments)" />
          <Values v-if="moduleRole('company_values')" @doChangeShowContent="doChangeShowContent(...arguments)" />
          <Environment v-if="moduleRole('environment')" @doChangeShowContent="doChangeShowContent(...arguments)" />
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from "@/libs/Global.vue"
// import Gen from "@/libs/Gen"
import SEOSection from "@/components/SEOSection"
import HeroSection from '@/components/HeroSection'
import Overview from './Overview'
import VisiMisi from './VisiMisi'
import Values from './Values'
import Environment from './Environment.vue'

export default {
  name: "BoLifeAtL9",
  extends: GlobalVue,
  components: {
    SEOSection,
    HeroSection,
    Overview,
    VisiMisi,
    Values,
    Environment
  },
  data(){
    return {
      
    }
  },
  methods: {
    doChangeShowContent(slug, value){
      Gen.apiRest("/do/BoLifeAtL9", {
        data: {
          type: 'submit-show-content',
          value: value,
          slug: slug
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }
  }
}

</script>