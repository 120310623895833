<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center justify-content-between">
        <b-card-title title-tag="h5">Vision & Mission</b-card-title>
        <b-button variant="outline-success" v-if="!showEdit" @click="openEdit(true)">Update Content</b-button>
        <div class="ml-auto" v-else>
          <!-- <b-button variant="transparent" @click="openEdit(false)">Cancel</b-button>
          <b-button variant="success" @click="submitVisiMisi">Save Content</b-button> -->
        </div>
      </div>
    </b-card-header>
    <template v-if="!showEdit">
      <b-card-body>
        <b-row class="mb-2">
          <b-col>
            <b-form-checkbox class v-model="showVisiMisi" @change="$emit('doChangeShowContent', 'show-visi-misi-about', $event)" value="Y" unchecked-value="N" name="showAbout">Show Section?</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="gx-2">
          <b-col md="6">
            <div class="vismis-card h-100">
              <div class="p-4">
                <b-row>
                  <b-col md="4">
                    <h3 class="font-bold">{{ visi.asc_title_id }}</h3>
                  </b-col>
                  <b-col md="8">
                    <span>{{ visi.asc_content_id }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="vismis-card h-100">
              <div class="p-4">
                <b-row>
                  <b-col md="4">
                    <h3 class="font-bold">{{misi.asc_title_id}}</h3>
                  </b-col>
                  <b-col md="8">
                    <span>{{ visi.asc_content_id }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </template>
    <template v-else>
      <b-card-body>
        <validation-observer ref="VFormVisiMisi" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitVisiMisi)">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <template #label>Title (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalVisi.asc_title_id" id="compOverTitle" />
                  <VValidate name="Title (ID)" v-model="dataModalVisi.asc_title_id" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Title (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalVisi.asc_title_en" id="compOverTitleEN" />
                  <VValidate name="Title (EN)" v-model="dataModalVisi.asc_title_en" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (ID)<span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModalVisi.asc_content_id" :rows="3"></b-form-textarea>
                  <VValidate name="Description (ID)" v-model="dataModalVisi.asc_content_id" rules="required|min:3" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (EN)<span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModalVisi.asc_content_en" :rows="3"></b-form-textarea>
                  <VValidate name="Description (EN)" v-model="dataModalVisi.asc_content_en" rules="required|min:3" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template #label>Title (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalMisi.asc_title_id" id="compOverTitle" />
                  <VValidate name="Title (ID)" v-model="dataModalMisi.asc_title_id" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Title (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalMisi.asc_title_en" id="compOverTitleEN" />
                  <VValidate name="Title (EN)" v-model="dataModalMisi.asc_title_en" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (ID)<span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModalMisi.asc_content_id" :rows="3"></b-form-textarea>
                  <VValidate name="Description (ID)" v-model="dataModalMisi.asc_content_id" rules="required|min:3" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (EN)<span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModalMisi.asc_content_en" :rows="3"></b-form-textarea>
                  <VValidate name="Description (EN)" v-model="dataModalMisi.asc_content_en" rules="required|min:3" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
      <b-card-footer>
        <div class="text-right">
          <b-button type="button" variant="transparent" @click="openEdit(false)">Cancel</b-button>
          <b-button @click="submitVisiMisi" variant="success" class="btn-rounded">
            <i class="fas fa-check mr-2"></i><span>Save Changes</span>
          </b-button>
        </div>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
const _ = global._

import Gen from '@/libs/Gen.js'

export default {
  data(){
    return {
      visi: {},
      misi: {},
      dataModalVisi: {},
      dataModalMisi: {},
      showVisiMisi: '',
      showEdit: false
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    openEdit(v){
      if(v){
        this.$set(this, 'dataModalVisi', _.clone(this.visi))
        this.$set(this, 'dataModalMisi', _.clone(this.misi))
      }

      this.showEdit = v
    },
    apiGet(){
      Gen.apiRest('/get/BoLifeAtL9/visimisi', {}, 'GET').then(res => {
        this.$set(this, 'visi', res.data.visi)
        this.$set(this, 'misi', res.data.misi)
        this.$set(this, 'showVisiMisi', res.data.showVisiMisi)
      })
    },
    submitVisiMisi(){
      this.$refs.VFormVisiMisi.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-visimisi',
            visi: this.dataModalVisi,
            misi: this.dataModalMisi 
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this, 'visi', res.data.data.visi)
          this.$set(this, 'misi', res.data.data.misi)
          this.showEdit = false
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
  }
}
</script>