<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title title-tag="h5">Our Work Environment</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox class v-model="showEnvironment" @change="$emit('doChangeShowContent', 'show-environment-about', $event)" value="Y" unchecked-value="N" name="showEnvironment">Show Section?</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="g-2" v-if="(environment.asc_data || []).length">
        <b-col md="6">
          <b-row class="g-2">
            <b-col sm="12">
              <div class="we-wrapper">
                <img :src="parent.uploader(environment.asc_data[0].image)" alt="">
                <div class="we-content-wrap">
                  <div class="we-content">
                    <span>{{ environment.asc_data[0].desc_id }}</span>
                  </div>
                  <div class="we-content">
                    <div class="we-media">
                      <img :src="parent.uploader(environment.asc_data[0].icon)" width="80" height="80">
                    </div>
                    <h4>{{ environment.asc_data[0].title_id }}</h4>
                  </div>
                </div>
                <div class="bullet-cta">
                  <b-button
                    variant="secondary"
                    size="sm"
                    pill
                    @click="openModalEnvironment(environment.asc_data[0], 0)"
                    class="btn-icon"
                    v-b-tooltip.hover="'Update Content'">
                    <i class="fas fa-pencil-alt" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="we-wrapper">
                <img :src="parent.uploader(environment.asc_data[1].image)" alt="">
                <div class="we-content-wrap">
                  <div class="we-content">
                    <span>{{ environment.asc_data[1].desc_id }}</span>
                  </div>
                  <div class="we-content">
                    <div class="we-media">
                      <img :src="parent.uploader(environment.asc_data[1].icon)" width="80" height="80">
                    </div>
                    <h4>{{ environment.asc_data[1].title_id }}</h4>
                  </div>
                </div>
                <div class="bullet-cta">
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="openModalEnvironment(environment.asc_data[1], 1)"
                    pill
                    class="btn-icon"
                    v-b-tooltip.hover="'Update Content'">
                    <i class="fas fa-pencil-alt" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="we-wrapper">
                <img :src="parent.uploader(environment.asc_data[2].image)" alt="">
                <div class="we-content-wrap">
                  <div class="we-content">
                    <span>{{ environment.asc_data[2].desc_id }}</span>
                  </div>
                  <div class="we-content">
                    <div class="we-media">
                      <img :src="parent.uploader(environment.asc_data[2].icon)" width="80" height="80">
                    </div>
                    <h4>{{ environment.asc_data[2].title_id }}</h4>
                  </div>
                </div>
                <div class="bullet-cta">
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="openModalEnvironment(environment.asc_data[2], 2)"
                    pill
                    class="btn-icon"
                    v-b-tooltip.hover="'Update Content'">
                    <i class="fas fa-pencil-alt" />
                  </b-button>
                </div>
              </div>
            </b-col>            
          </b-row>
        </b-col>
        <b-col md="6">
          <div class="we-wrapper">
            <img :src="parent.uploader(environment.asc_data[3].image)" alt="">
            <div class="we-content-wrap">
              <div class="we-content">
                <span>{{ environment.asc_data[3].desc_id }}</span>
              </div>
              <div class="we-content">
                <div class="we-media">
                  <img :src="parent.uploader(environment.asc_data[3].icon)" width="80" height="80">
                </div>
                <h4>{{ environment.asc_data[3].title_id }}</h4>
              </div>
            </div>
            <div class="bullet-cta">
              <b-button
                variant="secondary"
                size="sm"
                @click="openModalEnvironment(environment.asc_data[3], 3)"
                pill
                class="btn-icon"
                v-b-tooltip.hover="'Update Content'">
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>

    <b-modal id="modal_environment" title="Update Work Environment">
      <validation-observer ref="VFormEnvironment" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitEnvironment)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModal.title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModal.title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Description (ID) <span class="text-danger">*</span></template>
            <b-form-textarea v-model="dataModal.desc_id"></b-form-textarea>
            <VValidate name="Description (ID)" v-model="dataModal.desc_id" rules="required|min:3" />
          </b-form-group>
          <b-form-group>
            <template #label>Description (EN) <span class="text-danger">*</span></template>
            <b-form-textarea v-model="dataModal.desc_en"></b-form-textarea>
            <VValidate name="Description (EN)" v-model="dataModal.desc_en" rules="required|min:3" />
          </b-form-group>
          <b-form-group>
            <template #label>Icon <span class="text-danger">*</span></template>
            <Uploader v-model="dataModal.icon" type="about_environment_icon" />
            <VValidate name="Icon" v-model="dataModal.icon" rules="required" />
          </b-form-group>
          <b-form-group>
            <template #label>Image <span class="text-danger">*</span></template>
            <template v-if="dataModal.type_image == 'square'">
              <Uploader v-model="dataModal.image" type="about_environment" />
            </template>
            <template v-else-if="dataModal.type_image == 'widen'">
              <Uploader v-model="dataModal.image" type="about_environment_widen" />
            </template>
            <VValidate name="Image" v-model="dataModal.image" rules="required" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_environment')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitEnvironment">Submit</b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
const _ = global._

import Gen from '@/libs/Gen.js'

export default {
  data(){
    return {
      environment: [],
      dataModal: {},
      showEnvironment: '',
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    openModalEnvironment(v, index){
      this.$set(this, 'dataModal', _.clone(v))
      this.$set(this.dataModal, 'index', index)
      this.$bvModal.show('modal_environment')
    },
    apiGet(){
      Gen.apiRest('/get/BoLifeAtL9/environment', {}, 'GET').then(res => {
        this.$set(this, 'environment', res.data.data)
        this.$set(this, 'showEnvironment', res.data.showEnvironment)
      })
    },
    submitEnvironment(){
      this.$refs.VFormEnvironment.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-environment',
            ...this.dataModal
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.environment, 'asc_data', res.data.data)
          this.$bvModal.hide('modal_environment')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    submitIcon(){
      this.$refs.VFormIcon.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-icon',
            ...this.dataModalIcon,
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.valuesIcon, 'asc_data', res.data.data)
          this.$bvModal.hide('modal_icon')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    doDelete(id){
      this.parent.swalDelete()
      .then(result => {
        if(result.value){
          this.parent.swalLoading()

          Gen.apiRest("/do/BoLifeAtL9", {
            data: {
              type: 'delete-values-icon',
              id: id,
            }
          }).then(res => {
            this.$set(this.valuesIcon, 'asc_data', res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    }
  }
}
</script>