<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title title-tag="h5">Company Values</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox class v-model="showCompanyValues" @change="$emit('doChangeShowContent', 'show-company-values-about', $event)" value="Y" unchecked-value="N" name="showCompanyValue">Show Section?</b-form-checkbox>
        </b-col>
      </b-row>
      <b-tabs pills vertical end nav-wrapper-class="w-50 compval-nav" content-class="col-md-6" nav-class="row flex-row g-2">
        <b-tab title-item-class="col-md-6" title-link-class="h-100" v-for="(value, index) in values" :key="index">
          <template #title>
            <h5>{{ value.asc_title_id }}</h5>
            <span>{{ value.asc_content_id }}</span>
          </template>
          <bo-card-img :hideTitle="true" title="" :src="parent.uploader(value.asc_image)" :img-size="[640, 400]">
            <template #buttons>
              <b-button
                variant="secondary"
                @click="openModalValues(value, index)"
                size="sm"
                pill
                class="btn-icon"
                v-b-tooltip.hover="'Update Content'">
                <i class="fas fa-pencil-alt" />
              </b-button>
            </template>
          </bo-card-img>
        </b-tab>
      </b-tabs>
    </b-card-body>
    <b-table-simple hover caption-top responsive>
      <b-thead head-variant="green">
        <b-tr>
          <b-th>#</b-th>
          <b-th width="200px">Value</b-th>
          <b-th>Icon</b-th>
          <b-th>Status</b-th>
          <b-th>Action</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, index) in valuesIcon.asc_data" :key="index">
          <b-td>{{ index + 1 }}</b-td>
          <b-td>{{ value.title_id }} / {{ value.title_en }}</b-td>
          <b-td><img width="90px" :src="parent.uploader(value.image)"></b-td>
          <b-td>
            <span v-if="value.is_active == 'Y'" class="badge badge-success">Active</span>
            <span v-else class="badge badge-danger">Inactive</span>
          </b-td>
          <b-td>
            <button @click="openModalIcon(value)" class="btn btn-icon btn-secondary rounded-pill" target="_self"><i
            class="fas fa-pencil-alt"></i></button>
            <button @click="doDelete(value.id)" type="button" class="btn btn-icon btn-danger rounded-pill"><i
            class="far fa-trash-alt"></i></button>
          </b-td>
        </b-tr>
      </b-tbody>
      <tfoot>
        <b-tr>
          <b-th colspan="6" class="text-center">
            <button @click="openModalIcon({is_new: 'Y', is_active: 'Y'})" id="show-btn" type="button" class="btn btn-rounded btn-outline-success mt-2"><i
            class="fa fa-plus-circle mr-2"></i>Add New </button>
          </b-th>
        </b-tr>
      </tfoot>
    </b-table-simple>

    <b-modal id="modal_values" title="Company Values">
      <validation-observer ref="VFormValues" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitValues)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalValues.asc_title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalValues.asc_title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalValues.asc_title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalValues.asc_title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Description (ID) <span class="text-danger">*</span></template>
            <b-form-textarea v-model="dataModalValues.asc_content_id"></b-form-textarea>
            <VValidate name="Description (ID)" v-model="dataModalValues.asc_content_id" rules="required|min:3" />
          </b-form-group>
          <b-form-group>
            <template #label>Description (EN) <span class="text-danger">*</span></template>
            <b-form-textarea v-model="dataModalValues.asc_content_en"></b-form-textarea>
            <VValidate name="Description (EN)" v-model="dataModalValues.asc_content_en" rules="required|min:3" />
          </b-form-group>
          <b-form-group>
            <template #label>Image <span class="text-danger">*</span></template>
            <Uploader v-model="dataModalValues.asc_image" type="about_company_values" />
            <VValidate name="Image" v-model="dataModalValues.asc_image" rules="required" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_values')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitValues">Submit</b-button>
      </template>
    </b-modal>

    <b-modal id="modal_icon" title="Ideas Content">
      <validation-observer ref="VFormIcon" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitIcon)">
          <b-form-group>
            <template #label>Title (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalIcon.title_id"></b-form-input>
            <VValidate name="Title (ID)" v-model="dataModalIcon.title_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Title (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalIcon.title_en"></b-form-input>
            <VValidate name="Title (EN)" v-model="dataModalIcon.title_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Image <span class="text-danger">*</span></template>
            <Uploader v-model="dataModalIcon.image" type="about_company_values_icon" />
            <VValidate name="Image" v-model="dataModalIcon.image" rules="required" />
          </b-form-group>
          <b-form-group>
            <template #label>Background Color <span class="text-danger">*</span></template>
            <v-select
              v-model="dataModalIcon.color"
              :options="optionsBG"
              label="text"
              :reduce="v => v.value"
            >
              <template #option-selected="{ text, value }">
                <div class="d-flex justify-content-between align-items-center">
                  {{ text }}

                  <div :class="value" style="width: 40px; height: 40px;"></div>
                </div>
              </template>
            </v-select>
            <VValidate name="Background Color" v-model="dataModalIcon.color" rules="required" />
          </b-form-group>
          <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="parent.Config.mr.StatusOptions"
                v-model="dataModalIcon.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalIcon.is_active" 
                rules="required" 
              />
            </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_icon')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitIcon">Submit</b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
const _ = global._

import Gen from '@/libs/Gen.js'
import BoCardImg from '@/components/backend/BoCardImg.vue'

export default {
  components: {
    BoCardImg,
  },
  data(){
    return {
      values: [],
      dataModalValues: {},
      dataModalIcon: {},
      showEdit: false,
      showCompanyValues: '',
      valuesIcon: {},

      optionsBG: [
        { text: "Blue", value: "bg-blue" },
        { text: "Green", value: "bg-green" },
        { text: "Yellow", value: "bg-yellow" },
        { text: "Purple", value: "bg-purple" },
      ]
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    openModalValues(v, index){
      this.$set(this, 'dataModalValues', _.clone(v))
      this.$set(this.dataModalValues, 'index', index)
      this.$bvModal.show('modal_values')
    },
    openModalIcon(v){
      this.$set(this, 'dataModalIcon', _.clone(v))
      this.$bvModal.show('modal_icon')
       
    },
    apiGet(){
      Gen.apiRest('/get/BoLifeAtL9/values', {}, 'GET').then(res => {
        this.$set(this, 'values', res.data.data.values)
        this.$set(this, 'valuesIcon', res.data.data.valuesIcon)
        this.$set(this, 'showCompanyValues', res.data.showCompanyValues)
      })
    },
    submitValues(){
      this.$refs.VFormValues.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-values',
            ...this.dataModalValues
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.values, this.dataModalValues.index, res.data.data)
          this.$bvModal.hide('modal_values')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    submitIcon(){
      this.$refs.VFormIcon.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-icon',
            ...this.dataModalIcon,
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.valuesIcon, 'asc_data', res.data.data)
          this.$bvModal.hide('modal_icon')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    doDelete(id){
      this.parent.swalDelete()
      .then(result => {
        if(result.value){
          this.parent.swalLoading()

          Gen.apiRest("/do/BoLifeAtL9", {
            data: {
              type: 'delete-values-icon',
              id: id,
            }
          }).then(res => {
            this.$set(this.valuesIcon, 'asc_data', res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    }
  }
}
</script>