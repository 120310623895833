<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center justify-content-between">
        <b-card-title title-tag="h5">Overview</b-card-title>
        <b-button variant="outline-success" v-if="!showEdit" @click="openEdit(true)">Update Content</b-button>
        <div class="ml-auto" v-else>
          <!-- <b-button variant="transparent" @click="openEdit(false)">Cancel</b-button>
          <b-button variant="success" @click="submitOverview">Save Content</b-button> -->
        </div>
      </div>
    </b-card-header>
    <template v-if="!showEdit">
      <b-card-body>
        <b-row class="mb-2">
          <b-col>
            <b-form-checkbox class v-model="showOverview" @change="$emit('doChangeShowContent', 'show-overview-about', $event)" value="Y" unchecked-value="N" name="showAbout">Show Section?</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="paragraph-content">
              <h2>{{ overview.asc_title_id }}</h2>
              <div v-html="overview.asc_content_id" />
            </div>
          </b-col>
          <b-col md="6">
            <b-img :src="parent.uploader(overview.asc_image)" />
          </b-col>
        </b-row>
      </b-card-body>
    </template>
    <template v-else>
      <b-card-body>
        <validation-observer ref="VFormOverview" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitOverview)">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <template #label>Title (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalOverview.asc_title_id" id="compOverTitle" />
                  <VValidate name="Title (ID)" v-model="dataModalOverview.asc_title_id" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Title (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModalOverview.asc_title_en" id="compOverTitleEN" />
                  <VValidate name="Title (EN)" v-model="dataModalOverview.asc_title_en" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (ID)<span class="text-danger">*</span></template>
                  <QuillEditor 
                    id="articleContentID"
                    height="250px"
                    :value.sync="dataModalOverview.asc_content_id"
                    :customToolbar="itemsToolbar"
                  />
                  <VValidate name="Description (ID)" v-model="dataModalOverview.asc_content_id" rules="required|min:3" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (EN)<span class="text-danger">*</span></template>
                  <QuillEditor 
                    id="articleContentEN"
                    height="250px"
                    :value.sync="dataModalOverview.asc_content_en"
                    :customToolbar="itemsToolbar"
                  />
                  <VValidate name="Description (EN)" v-model="dataModalOverview.asc_content_en" rules="required|min:3" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <template #label>Image <span class="text-danger">*</span></template>
                  <Uploader v-model="dataModalOverview.asc_image" type="about_overview" />
                  <VValidate name="Image" v-model="dataModalOverview.asc_image" rules="required" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
      <b-card-footer>
        <div class="text-right">
          <b-button type="button" variant="transparent" @click="openEdit(false)">Cancel</b-button>
          <b-button @click="submitOverview" variant="success" class="btn-rounded">
            <i class="fas fa-check mr-2"></i><span>Save Changes</span>
          </b-button>
        </div>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
const _ = global._

import QuillEditor from '@/components/QuillEditor'
import Gen from '@/libs/Gen.js'

export default {
  components: {
    QuillEditor,
  },
  data(){
    return {
      itemsToolbar: [
        'heading',
        '|',
        'sourceEditing',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'outdent', 'indent',
      ],
      overview: {},
      dataModalOverview: {},
      showOverview: '',
      showEdit: false
    }
  },
  mounted(){
    this.apiGet()
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    openEdit(v){
      if(v){
        this.$set(this, 'dataModalOverview', _.clone(this.overview))
      }

      this.showEdit = v
    },
    apiGet(){
      Gen.apiRest('/get/BoLifeAtL9/overview', {}, 'GET').then(res => {
        this.$set(this, 'overview', res.data.overview)
        this.$set(this, 'showOverview', res.data.showOverview)
      })
    },
    submitOverview(){
      this.$refs.VFormOverview.validate().then(result => {
        if(!result) return

        this.parent.swalLoading()

        Gen.apiRest("/do/BoLifeAtL9", {
          data: {
            type: 'submit-overview',
            ...this.dataModalOverview  
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this, 'overview', res.data.data)
          this.showEdit = false
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
  }
}
</script>